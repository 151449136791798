import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import Header from './header';
import Footer from './footer';
import Favicon from '../images/favicon.ico';

import '../styles/styles.scss';

const Layout = ({
  children, index, theme, title,
}) => (
  <div className="main-wrapper">
    <Helmet
      defaultTitle="Veterinary Compounding Pharmacy | ZooPharm"
      titleTemplate="%s - ZooPharm"
      title={title}
      meta={[
        { name: 'description', content: 'Veterinary pharmacy specializing in veterinary compounding. Veterinary medications for pets, lab animals, deer (immobilization drugs), and zoo animals. Order refills online.' },
        { name: 'keywords', content: 'ZooPharm' },
      ]}
      htmlAttributes={{
        lang: 'en',
      }}
      bodyAttributes={{
        class: `theme-${theme}`,
      }}
      link={[
        { rel: 'icon', type: 'image/x-icon', href: `${Favicon}` },
      ]}
    />

    <Header index={index} />

    <main>{children}</main>

    <Footer />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.bool,
  title: PropTypes.string,
  theme: PropTypes.oneOf([
    'zoo-pharm',
    'companion-animals',
    'lab-animals',
    'wildlife',
    'zoo-animals',
    'home',
  ]),
};

Layout.defaultProps = {
  title: '',
  theme: 'zoo-pharm',
  index: false,
};

export default Layout;
