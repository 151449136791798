import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  children: PropTypes.element.isRequired,
};

const HeaderBanner = ({ children }) => (
  <div className="c-header-banner">
    {children}
  </div>
);

HeaderBanner.propTypes = propTypes;

export default HeaderBanner;
