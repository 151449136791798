import { graphql, Link, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import HomeIconPadlock from '../../images/icon-login-home.png';
import IconPadlock from '../../images/icon-login.png';
import Grid from '../grid/grid';
import GridItem from '../grid/item';


const propTypes = {
  index: PropTypes.bool,
};

const defaultProps = {
  index: false,
};

const TopNav = ({ index }) => {
  const topNavClass = index ? 'c-top-nav c-top-nav__index' : 'c-top-nav';

  return (
    <StaticQuery
      query={graphql`
        query TopNavQuery {
          site {
            siteMetadata {
              portalUrl
            }
          }
        }
      `}
      render={data => (
        <div className={topNavClass}>
          {index && (
            <Grid justifyContent="center">
              <GridItem auto>
                <ul className="c-nav c-nav-pills">
                  <li className="c-nav-item">
                    <Link to="/online-refills/" activeClassName="active">Online Refills</Link>
                  </li>
                  <li className="c-nav-item">
                    <Link to="/order-now/" activeClassName="active">How To Order</Link>
                  </li>
                  <li className="c-nav-item">
                    <Link to="/about-us/" activeClassName="active">About Us</Link>
                  </li>
                  <li className="c-nav-item">
                    <Link to="/news-and-events/" activeClassName="active">News & Events</Link>
                  </li>
                  <li className="c-nav-item">
                    <Link to="/faqs/" activeClassName="active">FAQs</Link>
                  </li>
                  <li className="c-nav-item">
                    <Link to="/register-for-access/" activeClassName="active">Register for Access</Link>
                  </li>
                  <li className="c-nav-item">
                    <a href={data.site.siteMetadata.portalUrl} target="_blank" rel="noopener noreferrer">
                      <picture className="icon-login">
                        <source media="(max-width: 768px)" srcSet={HomeIconPadlock} />
                        <img src={HomeIconPadlock} alt="ZooPharm" />
                      </picture>
                      Login
                    </a>
                  </li>
                </ul>
              </GridItem>
            </Grid>
          )}

          {!index && (
            <Grid justifyContent="end">
              <GridItem auto>
                <ul className="c-nav">
                  <li className="c-nav-item">
                    <Link to="/register-for-access/" activeClassName="active">Register for Access</Link>
                  </li>
                  <li className="c-nav-item">
                    <Link to="/order-now/" activeClassName="active">How To Order</Link>
                  </li>
                  <li className="c-nav-item">
                    <Link to="/online-refills/" activeClassName="active">Online Refills</Link>
                  </li>
                  <li className="c-nav-item">
                    <Link to="/about-us/" activeClassName="active">About Us</Link>
                  </li>
                  <li className="c-nav-item">
                    <Link to="/news-and-events/" activeClassName="active">News & Events</Link>
                  </li>
                  <li className="c-nav-item">
                    <Link to="/faqs/" activeClassName="active">FAQs</Link>
                  </li>
                  <li className="c-nav-item">
                    <Link to="/contact-us/" activeClassName="active">
                      Contact Us
                    </Link>
                  </li>
                  <li className="c-nav-item login-icon">
                    <a href={data.site.siteMetadata.portalUrl} target="_blank" rel="noopener noreferrer">
                      <picture className="icon-login">
                        <source media="(max-width: 768px)" srcSet={IconPadlock} />
                        <img src={IconPadlock} alt="ZooPharm" />
                      </picture>
                      Login
                    </a>
                  </li>
                </ul>
              </GridItem>
            </Grid>
          )}
        </div>
      )}
    />
  );
};

TopNav.propTypes = propTypes;
TopNav.defaultProps = defaultProps;
export default TopNav;
