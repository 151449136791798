import React from 'react';
import * as Yup from 'yup';
import { navigate } from 'gatsby';
import { Formik, Form, ErrorMessage } from 'formik';

import Request from '../utils/axios';
import Layout from '../components/layout';
import Grid from '../components/grid/grid';
import GridItem from '../components/grid/item';
import Hero from '../components/hero/hero';
import Card from '../components/card/card';
import Loader from '../components/loader/loader';
import Snackbar from '../components/snackbar/snackbar';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address: '',
  message: '',
};

const schema = Yup.object().shape({
  firstName: Yup.string()
    .required()
    .label('First Name'),
  lastName: Yup.string()
    .required()
    .label('Last Name'),
  phone: Yup.string()
    .required()
    .label('Phone'),
  email: Yup.string()
    .email()
    .required()
    .label('Email'),
  address: Yup.string()
    .required()
    .label('Address'),
  city: Yup.string()
    .required()
    .label('City'),
  state: Yup.string()
    .required()
    .label('State'),
  zip: Yup.string()
    .required()
    .label('Zip Code'),
  message: Yup.string()
    .required()
    .label('Message'),
});

const InnerForm = (formProps) => {
  const {
    values, status, touched, errors, handleChange, handleBlur,
  } = formProps;

  return (
    <Form className="c-contact-form">
      <Card>
        <Grid>
          <GridItem sm={6}>
            <div
              className={`form-group ${
                touched.firstName && errors.firstName ? 'form-group-invalid' : ''
              }`}
            >
              <label htmlFor="firstName">First Name *</label>
              <input
                type="text"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control"
              />
              <ErrorMessage name="firstName" component="div" className="error-message" />
            </div>
            <div
              className={`form-group ${
                touched.lastName && errors.lastName ? 'form-group-invalid' : ''
              }`}
            >
              <label htmlFor="lastName">Last Name *</label>
              <input
                type="text"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control"
              />
              <ErrorMessage name="lastName" component="div" className="error-message" />
            </div>
          </GridItem>
          <GridItem sm={6}>
            <div
              className={`form-group ${touched.email && errors.email ? 'form-group-invalid' : ''}`}
            >
              <label htmlFor="email">Email *</label>
              <input
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control"
              />
              <ErrorMessage name="email" component="div" className="error-message" />
            </div>
            <div
              className={`form-group ${touched.phone && errors.phone ? 'form-group-invalid' : ''}`}
            >
              <label htmlFor="phone">Telephone Number *</label>
              <input
                type="tel"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control"
              />
              <ErrorMessage name="phone" component="div" className="error-message" />
            </div>
          </GridItem>
          <GridItem sm={12}>
            <div
              className={`form-group ${
                touched.address && errors.address ? 'form-group-invalid' : ''
              }`}
            >
              <label htmlFor="address">Address *</label>
              <input
                type="text"
                name="address"
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control"
              />
              <ErrorMessage name="address" component="div" className="error-message" />
            </div>
          </GridItem>
          <GridItem sm={6}>
            <div
              className={`form-group ${
                touched.city && errors.city ? 'form-group-invalid' : ''
              }`}
            >
              <label htmlFor="city">City *</label>
              <input
                type="text"
                name="city"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control"
              />
              <ErrorMessage name="city" component="div" className="error-message" />
            </div>
          </GridItem>
          <GridItem sm={6}>
            <div
              className={`form-group ${
                touched.state && errors.state ? 'form-group-invalid' : ''
              }`}
            >
              <label htmlFor="state">State *</label>
              <input
                type="text"
                name="state"
                value={values.state}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control"
              />
              <ErrorMessage name="state" component="div" className="error-message" />
            </div>
          </GridItem>
          <GridItem sm={6}>
            <div
              className={`form-group ${
                touched.zip && errors.zip ? 'form-group-invalid' : ''
              }`}
            >
              <label htmlFor="zip">Zip Code *</label>
              <input
                type="text"
                name="zip"
                value={values.zip}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control"
              />
              <ErrorMessage name="zip" component="div" className="error-message" />
            </div>
          </GridItem>
          <GridItem sm={12}>
            <div
              className={`form-group ${
                touched.message && errors.message ? 'form-group-invalid' : ''
              }`}
            >
              <label htmlFor="message">Message *</label>
              <textarea
                name="message"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                className="form-control"
              />
              <ErrorMessage name="message" component="div" className="error-message" />
            </div>
            <div className="form-group">
              <button type="submit" className="c-button--primary c-button--block">
                SUBMIT
              </button>
            </div>
            {status && (
              <div className="error-message">
                <p>{status}</p>
              </div>
            )}
          </GridItem>
        </Grid>
      </Card>
    </Form>
  );
};

class ContactUsPage extends React.Component {
  state = {
    isSubmitting: false,
    hasErrors: false,
  };

  handleSubmit = async (values, { setSubmitting }) => {
    this.setState({ isSubmitting: true, hasErrors: false });
    try {
      await Request.post('/contact/', JSON.stringify(values), {
        headers: { 'Content-Type': 'application/json' },
      });

      setSubmitting(false);
      this.setState({ isSubmitting: false });
      navigate('/thank-you/');
    } catch (error) {
      this.setState({ isSubmitting: false, hasErrors: true });
      setSubmitting(false);
    }
  };

  handleDismiss = () => {
    this.setState({ hasErrors: false });
  };

  render() {
    const { isSubmitting, hasErrors } = this.state;
    return (
      <div>
        {isSubmitting && (<Loader text="Please wait ..." />)}
        <Layout title="Contact Us">
          <Hero
            backgroundImage="contact-us"
            headline="Contact Us"
            tagline="If you have a questions or would like to speak with a ZooPharm representative, please complete and submit the form below."
          />
          <section className="content-wrapper content-wrapper-contact-form">
            <Grid>
              <GridItem sm={12} md={7}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={schema}
                  onSubmit={this.handleSubmit}
                  render={formProps => <InnerForm {...formProps} />}
                />
              </GridItem>
              <GridItem sm={12} md={5}>
                <div className="c-contact-information">
                  <p>
                    You can also give us a call toll-free at
                    <span className="phone-number">1-866-823-9314</span>
                    <span className="email">info@wildpharm.com</span>
                  </p>
                </div>
              </GridItem>
            </Grid>
          </section>
        </Layout>
        <Snackbar message="There was an error submitting the form." show={hasErrors} type="danger" handleDismiss={this.handleDismiss} />
      </div>
    );
  }
}

export default ContactUsPage;
