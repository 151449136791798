import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Grid = ({
  children,
  alignItems,
  justifyContent,
  reverse,
  fluid,
  vertical,
}) => {
  const classes = classNames(
    'l-grid',
    {
      [`l-grid__align-items--${alignItems}`]: alignItems,
      [`l-grid__justify-content--${justifyContent}`]: justifyContent,
      'l-grid__reverse': reverse,
      'l-grid-fluid': fluid,
      'l-grid-vertical': vertical,
    },
  );

  return (
    <div className={classes}>
      {children}
    </div>
  );
};

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  alignItems: PropTypes.oneOf(['start', 'center', 'end', 'baseline']),
  justifyContent: PropTypes.oneOf(['start', 'center', 'end', 'space-between', 'space-evenly']),
  reverse: PropTypes.bool,
  fluid: PropTypes.bool,
  vertical: PropTypes.bool,
};

Grid.defaultProps = {
  alignItems: null,
  justifyContent: null,
  reverse: false,
  fluid: false,
  vertical: false,
};

export default Grid;
