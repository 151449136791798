import { Link } from 'gatsby';
import React from 'react';

const FooterNav = () => (
  <ul className="c-nav c-footer-nav">
    <li className="c-nav-item">
      <Link to="/register-for-access/">Register for Access</Link>
    </li>
    <li className="c-nav-item">
      <Link to="/about-us/">About Us</Link>
    </li>
    <li className="c-nav-item">
      <Link to="/news-and-events/">News & Events</Link>
    </li>
    <li className="c-nav-item">
      <Link to="/faqs/">FAQs</Link>
    </li>
  </ul>
);

export default FooterNav;
