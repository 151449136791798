import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Item = ({
  children,
  auto,
  xs,
  sm,
  md,
  lg,
}) => {
  const classes = classNames(
    'l-grid__item',
    {
      [`l-grid__item--col-xs-${xs}`]: xs,
      [`l-grid__item--col-sm-${sm}`]: sm,
      [`l-grid__item--col-md-${md}`]: md,
      [`l-grid__item--col-lg-${lg}`]: lg,
      'l-grid__item--auto': auto,
    },
  );

  return (
    <div className={classes}>
      {children}
    </div>
  );
};

Item.propTypes = {
  children: PropTypes.node.isRequired,
  auto: PropTypes.bool,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
};

Item.defaultProps = {
  auto: false,
  xs: null,
  sm: null,
  md: null,
  lg: null,
};

export default Item;
