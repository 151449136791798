import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Grid from '../grid/grid';
import GridItem from '../grid/item';

const propTypes = {
  children: PropTypes.node,
  backgroundImage: PropTypes.oneOf([
    'index',
    'zoo-pharm',
    'companion-animals',
    'lab-animals',
    'wildlife',
    'zoo-animals',
    'about',
    'contact-us',
  ]),
  headline: PropTypes.string,
  tagline: PropTypes.string,
  index: PropTypes.bool,
};

const defaultProps = {
  tagline: null,
  headline: null,
  children: null,
  backgroundImage: 'zoo-pharm',
  index: false,
};

const Hero = ({
  children, backgroundImage, headline, tagline, index,
}) => {
  const classes = classNames('c-hero', {
    [`c-hero--${backgroundImage}`]: backgroundImage,
  });

  return (
    <div>
      <section className={classes}>
        <div className="c-hero__wrapper">
          <h1>{headline}</h1>
          <p className="c-hero__tagline">{tagline}</p>
          {index && children}
        </div>
      </section>

      {!index && (
        <section className="c-hero__base">
          {children && (
            <Grid>
              <GridItem>{children}</GridItem>
            </Grid>
          )}
        </section>
      )}
    </div>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;
export default Hero;
