import React from 'react';
import IconFacebook from '../../images/icon-facebook.png';
import WedgewoodLogoWhite from '../../images/wedgewood-logo-white.png';
import Grid from '../grid/grid';
import GridItem from '../grid/item';
import FooterNav from '../nav/footer-nav';

const Footer = () => (
  <footer className="c-footer">
    <Grid justifyContent="space-between" alignItems="center">
      <GridItem sm={6} md={9}>
        <FooterNav />
        {/* <p>
          &copy;
          {` ${new Date().getFullYear()} ZooPharm`}
        </p> */}
      </GridItem>
      <GridItem sm={6} md={3}>
        <div className="c-footer__brand">
          <a href="https://www.facebook.com/wildpharm" target="_blank" rel="noopener noreferrer">
            <div className="footer-social-media">
              <span className="icon-facebook">
                <picture>
                  <source media="(max-width: 768px)" srcSet={IconFacebook} />
                  <img src={IconFacebook} alt="ZooPharm Facebook" />
                </picture>
              </span>
              <p>Follow Us on Facebook</p>
            </div>
          </a>
        </div>
      </GridItem>
    </Grid>
    <div className="c-footer--bottom">
      <Grid justifyContent="space-between" alignItems="center">
        <GridItem sm={12} md={9}>
          <div className="c-footer--bottom-logo">
            <img width="200" src={WedgewoodLogoWhite} alt="Wedgewood Pharmacy" />
            <p style={{ margin: '1em 0' }}>
              &copy;
              {` Copyright ${new Date().getFullYear()}. Wedgewood Pharmacy`}
            </p>
          </div>
        </GridItem>
        <GridItem sm={12} md={3}>
          <p style={{ margin: '1em 0' }}>
            <a className="c-footer--bottom-links" href="https://www.wedgewoodpharmacy.com/learning-center/web-site-privacy-policy.html">Privacy Policy</a>
            <a className="c-footer--bottom-links" href="https://www.wedgewoodpharmacy.com/learning-center/terms-of-use.html">Terms of Use</a>
          </p>
        </GridItem>
      </Grid>
    </div>
  </footer>
);

export default Footer;
