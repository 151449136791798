import React from 'react';
import PropTypes from 'prop-types';

const Card = ({ children, className }) => <div className={`c-card ${className}`}>{children}</div>;

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Card.defaultProps = {
  className: '',
};

export default Card;
