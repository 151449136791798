import React from 'react';
import PropTypes from 'prop-types';

const Modal = ({ children, card }) => {
  let content = children;
  if (card) {
    content = (
      <div className="c-modal">
        {children}
      </div>
    );
  }
  return (
    <div className="c-overlay">
      {content}
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  card: PropTypes.bool,
};

Modal.defaultProps = {
  card: true,
};

export default Modal;
