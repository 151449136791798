import React from 'react';
import {
  string, oneOf, func, bool,
} from 'prop-types';
import classNames from 'classnames';

const Snackbar = ({
  message, type, handleDismiss, show,
}) => {
  const classes = classNames({
    [`c-snackbar--${type}`]: type,
    [`${show ? 'active' : ''}`]: show,
  });

  return (
    <div className={classes}>
      <button type="button" onClick={handleDismiss} className="c-snackbar-close" />
      <div className="c-snackbar-text">{message}</div>
    </div>
  );
};

Snackbar.propTypes = {
  message: string.isRequired,
  type: oneOf(['success', 'danger']),
  show: bool,
  handleDismiss: func.isRequired,
};

Snackbar.defaultProps = {
  type: 'success',
  show: false,
};

export default Snackbar;
