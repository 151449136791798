import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../modal/modal';


const Loader = ({ text, size }) => (
  <Modal card={false}>
    <div>
      <span className={`spinner spinner--${size}`} />
      <span className="spinner--text">{text}</span>
    </div>
  </Modal>
);

Loader.propTypes = {
  size: PropTypes.string,
  text: PropTypes.string,
};

Loader.defaultProps = {
  size: 'medium',
  text: null,
};

export default Loader;
