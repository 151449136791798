import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import classNames from 'classnames';

class NavToggle extends Component {
  state = {
    open: false,
  };

  handleToggle = (e) => {
    const { open } = this.state;

    e.preventDefault();
    this.setState({ open: !open });
  };

  render() {
    const { open } = this.state;
    const toggleBtnClasses = classNames({
      open,
    });
    const overlayClasses = classNames('c-nav-overlay', {
      active: open,
    });

    return (
      <Fragment>
        <div className="c-nav-toggle">
          <Helmet
            bodyAttributes={{
              'data-nav-open': open,
            }}
          />
          <button type="button" onClick={this.handleToggle} className={toggleBtnClasses}>
            <span />
          </button>
        </div>
        <div role="presentation" onClick={this.handleToggle} className={overlayClasses} />
      </Fragment>
    );
  }
}

export default NavToggle;
