import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Logo from '../../images/zoopharm-logo.jpg';
import LogoRetina from '../../images/zoopharm-logo@2x.jpg';
import Grid from '../grid/grid';
import GridItem from '../grid/item';
import NavToggle from '../nav-toggle/nav-toggle';
import MainNav from '../nav/main-nav';
import TopNav from '../nav/top-nav';
import HeaderBanner from './banner';

const propTypes = {
  index: PropTypes.bool,
};

const defaultProps = {
  index: false,
};

const Header = ({ index }) => {
  const headerClass = index ? 'c-app-bar c-app-bar__index' : 'c-app-bar';

  return (
    <header className="c-header">
      <HeaderBanner>
        <a href="https://www.wedgewoodpharmacy.com/news/press-room/wedgewood-pharmacy-completes-acquisition-of-wildlife-pharmaceuticals-and-its-subsidiary-zoopharm.html">News: Wildlife Pharmaceuticals/ZooPharm acquisition</a>
      </HeaderBanner>
      <TopNav index={index} />

      <div className={headerClass}>
        <Grid justifyContent="space-between" alignItems="center">
          <GridItem auto>
            <div className="c-header__brand">
              <Link to="/">
                <picture>
                  <source media="(max-width: 768px)" srcSet={LogoRetina} />
                  <img src={Logo} alt="ZooPharm" />
                </picture>
              </Link>
            </div>
          </GridItem>

          <GridItem auto>
            <MainNav />
          </GridItem>
        </Grid>
      </div>

      <NavToggle />
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
